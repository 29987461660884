.actions-cell {
	position: relative;
	div {
		position: absolute;
		bottom: 15px;

	}
}

.tasks .disabled {
	background-color: #fff6f6;
	&:hover {
		td {
			background-color: #fff0f0;
		}
	}
}

.task-control {
	td {
		background-color: #fafafa;
	}
}

.tasks-mailings-list {
	.hide {
		display: none;
	}
}

.user-email-details-toggle {
	float:right;
}

.task-mail-detail-table {
	td {
		padding: 0 0 0 15px;
		background-color: #fafafa;
		border-bottom: 0px;
	}
}

.header {
	@include clearfix;

	margin-bottom: $base-spacing;

	div.left {
		float: left;
	}
	div.right {
		float: right;
		margin-top: $base-spacing;
		line-height: 31px;
		.button {
			margin-left: 10px;
		}
	}
}