
dl {
    padding: 0;
    margin: 0;
    dt {
        font-weight: bold;
    }
    dt, dd {
        display: inline-block;
    }
    div {
        display: block;
        padding-bottom: $small-spacing;
        &:last-child {
            padding-bottom: 0;
        }
    }
}

.user-data.card {
    margin-bottom: $base-spacing;
    .card-content {
        background: $white-ter;
    }
    .card-footer {
        padding: $small-spacing;
    }
}

.nav-extra {
    padding-bottom: $base-spacing;
    li {
        display: inline-block;
        padding-right: 5px;
        &.is-current {
            a {
                color: $black-ter;
                cursor: default;
            }
        }
        &:after {
            content: '•';
            padding-left: 10px;
        }
        &:last-child:after {
            content: '';
        }
    }
}
