
.container .rules-term-list {
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    h2.title.is-2 {
        margin-bottom: 0;
    }
    h4.title {
        margin-bottom: $small-spacing;
    }
    li {
        color: $black;
        background: rgba(#fff, 0.9);
        padding: $small-spacing;
        box-shadow: 0 0 2px rgba(black, 0.1);
        margin-bottom: $base-spacing;
    }
}

.area-toggle {
    display: block;
    width: 100%;
}

.area-toggle-trigger {
    color: $black;
    padding: 5px 5px;
    margin: 0 10px 0 10px;
    float: right;
}

.rules-term-list-container {
    padding-top: $base-spacing;
    &.hide {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }
}

.button.full-width-button {
    width: 100%;
}

.subscription-status {
    .notification {
        padding-right: 1.25rem;
    }
    span {
        display: inline-block;
    }
    button {
        margin-top: -6px;
        float: right;
    }
    label.checkbox {
        font-size: 16px;
    }
    input {
        margin-left: 5px;
        font-size: 32px;
    }
}

.area-header {
    h2 {
        display: inline-block;
    }
    .button {
        margin: 5px 0 0 5px;
    }
}

