$size-1: 3rem;
$size-2: 2.25rem;
$size-3: 1.75rem;
$size-4: 1.5rem;

$blue: hsl(220, 70%, 54%);
$primary: $blue;
$body-size: 0.925rem;

$base-spacing: 20px;
$small-spacing: $base-spacing / 2;

$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lighterer: hsl(0, 0%, 92%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

$orange: hsl(14, 100%, 53%);
$yellow: hsl(48, 100%, 67%);
$green: hsl(141, 71%, 48%);
$turquoise: hsl(171, 100%, 41%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 100%, 61%);
