.nav-menu-container {
    padding: $base-spacing 0 0;
    ul {
        li {
            display: inline-block;
            margin-right: $small-spacing * 1.5;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .is-4-desktop {
        ul {
            display: block;
            text-align: right;
        }
    }
}
