
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

@import "../../../node_modules/bulma/bulma.sass";
@import "../../../node_modules/select2/src/scss/core";

@import "categories";
@import "search";
@import "users";
@import "tasks";
@import "profile";
@import "header";
@import "footer";

html,
body {
    height:100%;
}

#app {
    min-height:100%;
    position:relative;
}

main.container {
    padding-bottom: $base-spacing * 7;
}

.main-page-footer {
    width:100%;
    position:absolute;
    bottom:0;
}

.is-hidden {
    &.is-visible {
        display: block !important;
    }
    &.modal.is-active {
        display: -webkit-box !important;
        display: flex !important;
    }
}

.main-page-header {
    margin-bottom: $base-spacing;
    &.hero.is-primary {
        .nav {
            margin-left: -0.75rem;
            padding-top: 1rem;
            box-shadow: none;
        }
    }
    .hero-body {
        padding: 2rem 0;
    }
}

.main-page-footer {
    &.hero.is-primary {
        .nav {
            margin-left: -0.75rem;
            padding-top: 1rem;
            box-shadow: none;
        }
    }
    .hero-body {
        padding: 2rem 0;
    }
}




.breadcrumbs {
    &.notification {
        padding: $small-spacing * 1.5;
    }
    .seperator {
        padding: 0 ($small-spacing / 2);
    }
}

form.inline-form {
    display: inline-block;
}

tr.inactive {
    color: #888;
}

.filters {
    padding-bottom: $base-spacing;
    .aligned {
        display: inline-block;
        padding-right: $small-spacing;
    }
}

.delete-rule {
    margin-top: $base-spacing;
}

.user-edit-actions {
    padding: $base-spacing 0;
}


fieldset {
    margin: $base-spacing 0;
    padding: 1em;
    border: 1px solid $white-ter;
    legend {
        color: $input;
        font-size: 16px;
        display: block;
        font-weight: bold;
    }
}

.create-header {
    @include clearfix;
    h1 {
        float: left;
    }
    .button {
        margin-top: $base-spacing;
        float: right;
    }
}

.content .body {
    border-bottom: 1px solid $grey-lighter;
    padding-bottom: $small-spacing;
    margin-bottom: $small-spacing;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to,
.fade-leave-active {
    opacity: 0
}

.rule-collection {
    background-color: $white-ter;
    padding: $small-spacing;
    margin-bottom: $base-spacing;
    input[type='checkbox'] {
        margin-top: 3px;
        font-size: 28px;
    }
    @include desktop() {
        padding: $base-spacing;
    }
}

.rule-collection-section {
    h3.title {
        background: $white-ter;
        padding: $small-spacing;
        position: relative;
        .icon-up {
            display: none;
        }
        .icon {
            color: $text;
        }
        &.open {
            .icon-down {
                display: none;
            }
            .icon-up {
                display: block;
            }
        }
    }
    .rule-section-trigger {
        position: absolute;
        right: $base-spacing;
        top: 12px;
    }
}

.single-sub-excerpt {
    padding-bottom: $base-spacing;
    margin-bottom: $base-spacing * 2;
    border-bottom: 1px solid $grey-lighter;
    &:last-child {
        border-bottom: none;
    }
    .single-sub-excerpt--body {
        padding-bottom: $small-spacing;
    }
}

.toggle {
    .single-sub-excerpt {
        padding-left: $base-spacing;
        padding-right: $base-spacing;
    }
}

#info-privacy {
    ul {
        list-style-type: circle;
        margin-left: $base-spacing;

    }
}

#info-prices {
    table {
        tr {
            td{
                .price {
                    font-size: $size-4;
                    font-weight: bold;
                }
                border-bottom: 1px solid whitesmoke;
                text-align: center;
            }
            th{
                text-align: center;
                background-color: whitesmoke;
                border-bottom: 0px;
            }
            &:hover {
                background: inherit;
            }


        }
        border: 1px solid whitesmoke;
    }
    .gray-background {
        background-color: whitesmoke;
        border-bottom: 0px;
        padding: 15px;
    }
}

span.select select {
    min-width: 200px;
}

label.label.select2-label {
    font-weight: normal;
}

select.select2 {
    margin-top: $small-spacing / 2;
    width: 100%;
}

h4.rules-term-title {
    margin-bottom: $small-spacing !important;
    padding-bottom: $small-spacing;
    border-bottom: 1px solid $grey-light;
}

body .pagination {
    display: block;
    font-size: 16px;
    li {
        display: inline-block;
        padding: 0 3px;
    }
}

th.actions-col,
td.actions-col {
    min-width: 220px;
}