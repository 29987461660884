
.search-form-filters {
    padding-bottom: $base-spacing * 2;
}

.select {
    &,
    select {
        width: 100%;
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all .3s ease;
    opacity: 1;
}
.slide-fade-enter,
.slide-fade-leave-active {
    opacity: 0;
}

.button.search-toggle {
    margin-top: -5px;
}
